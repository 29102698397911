import React, { Fragment, Component } from "react";
import { navigate } from "gatsby-link";

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

export default class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false };
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch(`${process.env.KDC_CMS_API}/contact`, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state
      })
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error));
  };

  render() {
    return (
      <Fragment>
        <div className="w-md-80 w-lg-60 text-center mx-md-auto mb-9">
          <span className="u-label u-label--sm u-label--red mb-3">
            Leave a Message
          </span>
          <h2 className="h3">How can we help you?</h2>
          <p>
            Whether you have questions or you would just like to say hello,
            contact us.
          </p>
        </div>

        <div className="w-lg-80 mx-auto">
          <form
            name="Contact Us"
            className="js-validate"
            data-netlify="true"
            netlify-honeypot="bot-field"
            method="POST"
            action="/thank-you/"
            onSubmit={this.handleSubmit}
          >
            <div className="row">
              <div className="col-sm-6 mb-6">
                <div className="js-form-message">
                  <div className="js-focus-state input-group form">
                    <input
                      className="form-control form__input"
                      type="text"
                      name="name"
                      required
                      placeholder="Your name"
                      aria-label="Your name"
                      // data-msg="Please enter your name."
                      // data-error-className="u-has-error"
                      // data-success-className="u-has-success"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>

              <div className="col-sm-6 mb-6">
                <div className="js-form-message">
                  <div className="js-focus-state input-group form">
                    <input
                      className="form-control form__input"
                      type="email"
                      name="email"
                      required
                      placeholder="Your email address"
                      aria-label="Your email address"
                      // data-msg="Please enter a valid email address."
                      // data-error-className="u-has-error"
                      // data-success-className="u-has-success"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>

              <div className="w-100"></div>

              <div className="col-sm-6 mb-6">
                <div className="js-form-message">
                  <div className="js-focus-state input-group form">
                    <input
                      className="form-control form__input"
                      type="text"
                      name="subject"
                      required
                      placeholder="Subject"
                      aria-label="Subject"
                      // data-msg="Please enter a subject."
                      // data-error-className="u-has-error"
                      // data-success-className="u-has-success"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>

              <div className="col-sm-6 mb-6">
                <div className="js-form-message">
                  <div className="js-focus-state input-group form">
                    <input
                      className="form-control form__input"
                      type="tel"
                      name="phone"
                      required
                      placeholder="Your phone number"
                      aria-label="Your phone number"
                      // data-msg="Please enter a valid phone number."
                      // data-error-className="u-has-error"
                      // data-success-className="u-has-success"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="js-form-message mb-9">
              <div className="js-focus-state input-group form">
                <textarea
                  className="form-control form__input"
                  rows="6"
                  name="message"
                  required
                  placeholder="How can we help you?"
                  aria-label="How can we help you?"
                  // data-msg="Please enter a reason."
                  // data-error-className="u-has-error"
                  // data-success-className="u-has-success"
                  onChange={this.handleChange}
                ></textarea>
              </div>
            </div>

            <div className="d-none">
              <label>
                Don’t fill this out if you're human:{" "}
                <input name="address" onChange={this.handleChange} />
              </label>
            </div>

            <div className="text-center">
              <button type="submit" className="btn btn-primary btn-wide mb-4">
                Submit
              </button>
              <p className="small">
                We'll get back to you in 1-3 business days.
              </p>
            </div>
          </form>
        </div>
      </Fragment>
    );
  }
}
