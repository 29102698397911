import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import HeroBanner from "../components/hero-banner";
import Address from "../components/address";
import Contact from "../components/contact";

export default ({ data }) => (
  <Layout>
    <SEO
      title="Products"
      keywords={[
        "electrical",
        "Philippines",
        "design",
        "build",
        "system",
        "supply",
        "troubleshoot",
        "repair",
        "equipments",
        "test",
        "manage",
        "bms"
      ]}
    />

    <div className="gradient-half-primary-v1">
      <HeroBanner
        desktopWide={data.bgDesktopWide.childImageSharp.fluid.src}
        desktop={data.bgDesktop.childImageSharp.fluid.src}
        tablet={data.bgTablet.childImageSharp.fluid.src}
        mobile={data.bgMobile.childImageSharp.fluid.src}
        mobileXS={data.bgMobileXS.childImageSharp.fluid.src}
      >
        <h1 className="display-2 font-size-48--md-down text-white mb-0">
          Contact Us
        </h1>
      </HeroBanner>
    </div>

    <div className="container space-2 space-3--lg">
      <Address
        area="Metro Manila Office"
        address="#5 General Lim St., San Antonio Village, Pasig City, Philippines"
        phone="(+632) 477 3408 / (+632) 697 5996"
        fax="(+632) 477 2188"
        email="info@static.ph"
        hours="Mon - Fri: 8:30AM - 05:30PM"
        lat={14.5798181}
        lng={121.0628535}
        zoom={18}
      />

      <hr className="my-9" />

      <Address
        area="Cebu Office"
        address="2B 2nd Floor, M and J Ortiz Bldg V Rama Ave, Guadalupe Cebu City"
        phone="(+6332) 232 0556"
        fax="(+632) 477 2188"
        email="info@static.ph"
        hours="Mon - Fri: 8:30AM - 05:30PM"
        lat={10.3168063}
        lng={123.8854694}
        zoom={18}
      />
    </div>

    <hr className="my-0" />

    <div id="form" className="container space-1 space-3--lg">
      <Contact />
    </div>
  </Layout>
);

export const query = graphql`
  query {
    bgDesktopWide: file(relativePath: { eq: "bg3.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    bgDesktop: file(relativePath: { eq: "bg3.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    bgTablet: file(relativePath: { eq: "bg3.png" }) {
      childImageSharp {
        fluid(maxWidth: 992, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    bgMobile: file(relativePath: { eq: "bg3.png" }) {
      childImageSharp {
        fluid(maxWidth: 768, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    bgMobileXS: file(relativePath: { eq: "bg3.png" }) {
      childImageSharp {
        fluid(maxWidth: 576, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;
