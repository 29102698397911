import React from "react";
import GoogleMapReact from "google-map-react";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import marker from "../images/map-marker1.png";

const GMapMarker = ({ lat, lng, text }) => (
  <img
    src={marker}
    alt={text}
    title={text}
    lat={lat}
    lng={lng}
    $hover={undefined}
  />
);

// const GMapMarkerText = ({ lat, lng, text }) => <div>{text}</div>;

const GMap = ({ lat, lng, zoom, text }) => (
  <GoogleMapReact
    bootstrapURLKeys={{
      key: "AIzaSyBfpM7TOPpZBw-wAt0T_wIuCz638brf9wY"
    }}
    defaultCenter={{ lat, lng }}
    defaultZoom={zoom}
  >
    <GMapMarker lat={lat} lng={lng} text={text} />
    {/* <GMapMarkerText lat={lat} lng={lng} text={text} /> */}
  </GoogleMapReact>
);

const Address = ({
  area,
  address,
  phone,
  fax,
  email,
  hours,
  lat,
  lng,
  zoom
}) => {
  return (
    <div className="row">
      <div className="col-lg-6 mb-7 mb-lg-0">
        <h1 className="h4 mb-4">{area}</h1>

        <div className="row">
          <div className="col-sm-6 mb-4 mb-sm-0">
            <h2 className="h6">Details:</h2>
            <address className="mb-0">
              <ul className="list-unstyled font-size-14 text-secondary mb-0">
                <li className="py-1">{address}</li>
                <li className="py-2"></li>
                <li className="py-1">{phone}</li>
                <li className="py-1">Fax: {fax}</li>
                <li className="py-1">
                  Email:{" "}
                  <OutboundLink href={`mailto:${email}`}>{email}</OutboundLink>
                </li>
              </ul>
            </address>
          </div>

          <div className="col-sm-6">
            <h3 className="h6">Business hours:</h3>
            <span className="d-block font-size-14 text-secondary mb-3">
              {hours}
            </span>
            {/* <img className="img-fluid" src="../../assets/img/400x180/contact.jpg" alt="Image Description"/> */}
          </div>
        </div>
      </div>

      <div className="col-lg-6 gmap-wrapper">
        <div className="js-g-map embed-responsive h-100">
          <GMap lat={lat} lng={lng} zoom={zoom} text={area} />
        </div>
      </div>
    </div>
  );
};

export default Address;
